.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.banner-image-vector {
  width: 50vw !important;
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .banner-image-vector {
    margin-left: -3vw !important;
  }
}

@media (min-width: 1440px) and (max-width: 2560px) {
  .banner-image-vector {
    margin-left: -4vw !important;
  }
}

/* THESE MODAL STYLES TO KEEP THE MODAL AT CENTER */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
}

@media (min-width: 1440px) and (max-width: 2560px) {
  .modal {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .modal {
    width: 70%;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .modal {
    width: 90%;
  }
}

@media (max-width: 424px) {
  .modal {
    width: 100%;
  }
}

.navigation .current {
  color: #1967d2 !important;
}

.navigation a:hover {
  color: #155a9d !important;
}

.testimonial-style {
  width: 50vw !important;
  margin: 0 auto !important;
}

.scroll-to-top1 {
  position: fixed;
  right: 20px;
  bottom: 28px;
  z-index: 999;
  width: 40px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  background-color: #23293e;
  color: #1967d2;
  background-color: rgba(25, 103, 210, 0.07);
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top1:hover {
  color: #ffffff;
  background-color: #1967d2;
}

.scroll-to-top1.style-two {
  color: #34a853;
  background-color: rgba(52, 168, 83, 0.07);
}
.scroll-to-top1.style-two:hover {
  color: #ffffff;
  background-color: rgba(52, 168, 83, 1);
}

.scroll-to-top1 {
  position: fixed;
  right: 20px;
  bottom: 28px;
  z-index: 999;
  width: 40px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  background-color: #23293e;
  color: #1967d2;
  background-color: rgba(25, 103, 210, 0.07);
  transition: all 300ms ease;
}

.scroll-to-top1:hover {
  color: #ffffff;
  background-color: #1967d2;
}

.scroll-to-top1.style-two {
  color: #34a853;
  background-color: rgba(52, 168, 83, 0.07);
}

.scroll-to-top1.style-two:hover {
  color: #ffffff;
  background-color: #34a853;
}

.sticky-header1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 100;
  animation: slideDown 0.3s ease;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.nav-lins-btns {
  margin-bottom: 0 !important;
}

.pills {
  position: relative;
  font-size: 13px;
  line-height: 15px;
  margin-right: 15px;
  padding: 5px 20px;
  border-radius: 50px;
  margin-bottom: 10px;
  position: absolute;
  right: 10px;
  margin-right: 0px;
  border-radius: 40px;
}

.blue-pills {
  background: rgba(25, 103, 210, 0.15);
  color: #1967d2;
}

.orange-pills {
  background: rgba(249, 171, 0, 0.15);
  color: #f9ab00;
}

.green-pills {
  background: rgba(52, 168, 83, 0.15);
  color: #34a853;
}

.snackbar {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  z-index: 1000;
  /* LITTLE ANIMATION */
  animation: slideIn 0.5s forwards, fadeOut 0.5s 3s forwards;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

.snackbar p {
  font-weight: 500;
  margin: 0;
  flex-grow: 1;
}

.snackbar .close-btn {
  margin: 0 16px; 
}

.snackbar.success {
  background-color: #DEF2D7;
  color: #5B7052;
}
.snackbar.success p, .snackbar.success span {
  color: #5B7052;
}

.snackbar.info {
  background-color: #cde9f6;
  color: #4780aa;
}
.snackbar.info p, .snackbar.info span {
  color: #4780aa;
}

.snackbar.error {
  background-color: #ECC8C5;
  color: #AB3331;
}

.snackbar.error p, .snackbar.error span {
  color: #AB3331;
}

.snackbar.warning {
  background-color: #f7f3d7;
  color: #927238;
}
.snackbar.warning p, .snackbar.warning span {
  color: #927238;
}

.social-share a {
  display: flex;
  align-items: center;
  gap: 7px;
}

.job-detail p {
  /* text-indent: 2rem; */
}
.job-detail p::first-letter {
  text-transform: capitalize;
}

/* SKELETION LOADING */
.skeleton-loading {
  min-height: 20px;
  min-width: 100px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin: 5px 0;
  animation: pulse 1.5s infinite;
}
.skeleton-loading-img {
  width: 100%;
  max-width: 100px;
  height: auto;
  background-color: #f0f0f0;
  border-radius: 10%;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}

.job-search-form-two form {
  border-radius: 10px;
}