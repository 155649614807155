.mobile-nav1 {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 88vh;
  background-color: #202124;
  color: #979797;
  transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translateY(100%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.mobile-nav1.show1 {
  transform: translateY(0);
}

.navhead {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-shrink: 0;
}

hr:not([size]) {
  margin-top: 0 !important;
}

.content-container {
  overflow-y: auto;
  flex-grow: 1;
  height: calc(100% - 120px);
}

.nav-content1 {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}
.nav-content1 ul {
  padding-top: 20px;
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}
.nav-content1 li {
  margin-bottom: 10px;
  padding: 0 20px;
  margin: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.nav-link1 {
  color: #979797;
  padding: 10px 20px;
  text-decoration: none;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%;
  background: rgba(3, 2, 1, 0);
  border-color: inherit;
  width: auto;
  padding-right: 50px;
  position: relative;
}

.nav-link1.active {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  line-height: 30px;
}

.nav-link1:hover {
  color: #ffffff;
}

.jp-btn-container {
  width: 100%;
  margin-top: 87px;
  margin-bottom: 10px;
  padding: 0 20px;
}

.jp-btn {
  width: 100%;
  padding: 1rem 0;
  height: 60px;
  border-radius: 10px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
}

.mobile-menu-contact {
  padding: 18px 20px 0 20px;
}

.mobile-menu-contact p {
  font-size: 0.85rem;
}
